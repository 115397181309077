html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}
:root{
    /* Colors */
    --h1-color: #fff;
    --h2-color: #445266    ;
    --site-header-color: #fff;
    --content-color: #6F6C70;
    --content-title-color: #595959;
    

    /* Header sizes */
    --h1-size: 48px;
    --h1-size-mobile: 30px;
    --h2-size: 33px;
    --h2-size-mobile: 25px
}



*,
*:before,
*:after {
    padding: 0;
    margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  
}


@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    /* margin-bottom: 72px; */
}

h1 .thin {
    font-weight: 300;

}

h1+p {
    margin-top: 0px;

}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
    
}

p, ol li {
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
    position: center;    
    margin: 20px 0;
    color: var(--content-color);
}
p+p{
    margin-top: 0;
}
ul {
    list-style: none;
}

a {
    text-decoration: none;
}
.scroll-to-link > *{
    pointer-events: none;
 }
.blur-circle-right{
    height: 100%;
    left: auto;
    top: 0;
    width: 40%;
    background: #2CC3C6;
    bottom: 0;
    filter: blur(300px);
    -webkit-filter: blur(300px);
    -moz-filter: blur(300px);
    -ms-filter: blur(300px);
    -o-filter: blur(300px);
    opacity: 0.4;
    -moz-opacity: 0.3;
    -webkit-opacity: 0.5;
    position: absolute;
    right: 0;
}
.text-white{
    color: white;
}


/* Banner */

.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 10;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin: 0 0 0 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }




.site-logo {
    font-weight: bold;
    font-size: 22px;
    color: #040603;
    background: url("../image/logo.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    margin-top: 120px;
}

.logo-wrapper {
    padding-top: 0px;
    padding-bottom: 22px;
    display: flex;
    justify-content: center;
    height: 161px;
    position: relative;
    z-index: 1;
}
.site-header {
    color: #fff;
    background-color: #061844;
    position: relative;
    overflow: hidden;
}
.site-header-inner {
    position: relative;
    padding: 45px 60px 75px;
    text-align: center;
}

.header-image {
    position: absolute;
    right: 15px;
    bottom: 0;
    /* background-image: url("../image/radial-gradient.png");
    width: 1373px;
    height: 1263px; */
    /* TODO */
}

.image-wrapper{
    position: absolute;
    background-image: url("../image/background-image.webp");
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.header-description {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-bottom: 30px;
    line-height: 1.5;
    font-weight: 300;
}

.nav-wrapper{
    width: 915px;
    position: relative;
    font-size: 17px;
    font-weight: 700;
    margin:0 auto;
    }

.nav-wrapper-bg{
    border-radius: 69px;
    opacity: 0.15;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.cta-btns{
    position: relative;
    z-index: 2;
    padding: 15px 50px;
}





.btn-lis a{
    color: #2CC3C6 ;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 25px;
    background-size: contain;
    -webkit-transition: -webkit-filter .15s linear;
    -moz-transition: -moz-filter .15s linear;
    -moz-transition: filter .15s linear;
    -ms-transition: -ms-filter .15s linear;
    -o-transition: -o-filter .15s linear;
    transition: filter .15s linear,-webkit-filter .15s linear;
}
.btn-lis a img{
    margin-right: 5px;
    margin-bottom: 2px;
}
.draw-software-li a{
    background-image: url("../image/tool.png");
}
.feature-li a{
    background-image: url("../image/sell.png");
}
.how-to-li a{
    background-image: url("../image/vr.png");
}
.where-to-li a{
    background-image: url("../image/faqs.png");
}

.draw-software-li a:hover, .feature-li a:hover, .how-to-li a:hover, .where-to-li a:hover{
    filter: brightness(0) invert(1);
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
    justify-content: space-evenly;
}

.nav-wrapper li {
    margin-right: 31px;
 }

/* H2-HEADERS */
 .h2-header{
     margin-top: 40px;
     color: var(--h2-color);
     font-size: var(--h2-size);
     font-weight: 700;
     text-align: center;
 }

 .articles{
     align-items: center;
     text-align: center;
     margin-bottom: 50px;
 }
.artcontent{
    text-align: left;
    display: table;
    margin: 0 auto;
    padding: 40px 30px 0;

}

.artcontent ol  {
    list-style: none; 
    counter-reset: li
}

.artcontent ol li::before {
    content: counter(li)'.'; 
    color: #44B9BB;    ;
    display: inline-block; 
    font-weight: 800;
    width: 1em;
    margin-right: 5px;
}

.artcontent ol li{
    font-weight: 700;
    counter-increment: li
}
.artcontent ol li+li{
    margin-top: 20px;

    
}
.navigation-articles-desktop{
    max-width: 1287px;
    box-shadow: 0px 0px 25px 0px #00000014;
    border-radius: 20px;
    padding: 12px;
    margin-top: 65px;
}
.navigation-tabs{
    width: 100%;
    
}
.navigation-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;  
}

.navigation-grid li{
    text-align: center;
    padding: 25px 12px 0;
    border-radius: 20px 20px 0 0 ;
}
.grey-bg{
    background-color: #f2f2f2;
}
.tab-1,
.tab-3{
    color: var(--content-title-color);
    
}
.tab-2{
    color: #DDA8BA;
}
.content-title{
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 45px;
}

.content{
    text-align: center;
    font-size: 14px;
    color: var(--content-color);

    /* position: absolute;
    transform: translateX(150%); */
}

.navigation-content{
    padding: 30px 20px;
    text-align: center;
    line-height: 30px;
    border-radius: 0 0 22px 22px;
    /* background-color: #f2f2f2; */

    /* position: relative;
    overflow: hidden; */
}
.tablinks{
    cursor: pointer;
}

.tablinks.active{
    background-color: #f2f2f2;
    transition: 0.3s;
}
.tablinks * {
    pointer-events: none;
}

.tabcontent{
    display: none;
}
.tabcontent.active {
    display: block;
}

.features-content{
    max-width: 1287px;
    box-shadow: 0px 0px 25px 0px #00000014;
    border-radius: 20px;
    margin-top: 53px;
    padding: 34px 40px 40px;
}
.features-content-description{
    font-size: 14px;
    color: var(--content-color);
    line-height: 26px;
    margin-top: 20px;
}
.features-headers{
    font-size: 22px;
    color: var(--content-title-color);
    text-transform: uppercase;
    margin-left: 23px;
    position: relative;
    
}
.features-headers::before{
    content: "";
    width: 45px;
    height: 45px;
    background-color: #E1A4B433;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    top: -8px;
    left: -24px;
}

.steps{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
}
.steps li{
    display: flex;  
    position: relative;
    align-items: center;

}
.steps li svg{
    height: 3px;
    margin-left: 16px;
    margin-right: 16px;
}
/* .steps li::after{
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: red;
} */
.steps li p{
    color: #78B3E5;
    font-size: 15px;
    font-weight: 600;
}
.steps li img{
    width: 30px;
    height: 30px;
    margin-right: 11px;
}
.create-nft{
    text-align: center;
}
.create-nft-content{
    padding-left: 30px;
    text-align: center;
}
.best-marketplace-wrapper{
    display: flex;
    align-items: center;
}
.our-team-wrapper{
    background-color: #F5F4F7;
}
.wrapper-inner{
    padding-top: 88px;
    padding-bottom: 100px;
    text-align: center;
}
.wrapper-inner h2{
    font-size: var(--h2-size);
    color: #565456;
    ;
    text-align: center;
}
.bullet-points{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--content-color);
    font-weight: 600;
    font-size: 15px;
}
.bullet-points p{
    padding-left: 5px;
    width: 100%;
}
.bullets{
    margin-top: 55px;
    position: relative;
}

.faq-wrapper-outer{
    background-color: #061844;
    padding-top: 85px;
    overflow: hidden;
}
.bg-gradient{
    position: relative;
}
.bg-gradient-inner{
    background-image:url("../image/faq-image-right.webp");
    position: absolute;
    width: 2000px;
    height: 2000px;
    top: -565px;
    left: 372px;
    position: absolute;
    z-index: -1;
}
.faq-wrapper{
    position: relative;
    z-index: 2;
}
.faq-section-wrapper{
    padding-bottom: 90px;
}

.faq-heading {
    margin-bottom: 38px!important;
    padding-top: 68px!important
}

.faq.expanded+.faq {
    border-top: 0
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.faq-title h3 {
    color: var(--h1-color);
    font-size: 22px;
    font-weight: 400;
    height: 60px;
    line-height: 28.16px;
    pointer-events: none
}

.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 42px;
    height: 49px;
    justify-content: center;
    margin-right: 42px;
    width: 49px
}



.faq-btn:hover,.faq-title:hover {
    cursor: pointer
}



.faq {
    padding-left: 40px;
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    margin-bottom: 25px;
}
.faq-background-outer{
    background-color: white;
    opacity: 0.3;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.faq-content{
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: white;
    font-size: 15px;
    line-height: 26px;
    padding-bottom: 44px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 25px;
    width: 95%;
}

.faq.collapsed .faq-body {
    display: none
}
.faq-body a{
    font-weight: bold;
    color: white;
    text-decoration: underline;
}



.faq-btn span {
    pointer-events: none
}

.faq.collapsed .faq-btn span {
    color: white
}

.faq.expanded .faq-btn span {
    color: white
}

.faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block
}

.faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none
}

.faq.expanded .faq-title h3 {
    font-weight: 700
}
.text-center {
    text-align: center;
    color: white;
}
.text-center p{
    padding-bottom: 50px;
    color: #fff;
}
.faq-title h3{
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
}
.faq-body p{
    color: white;
}

.contact-us-outer{
    position: relative;
}
.contact-us-inner{
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 60px 0 70px
}
.contact-us-inner h2{
    font-size: var(--h2-size);
    color: white;
    font-weight: 700;  
}
.contact-us-inner p{
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
}
.contact-btn{
    background-color: #17F6FA;
    font-size: 16px;
    padding: 20px 50px;
    border-radius: 25px;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #3C3F46;
    position: relative;
}
/*
.contact-btn:hover:before{
    background: #fff;
    border-radius: 25px;
    bottom: 0;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #1a464f;
    content: "Services under development";
    height: inherit;
    justify-content: center;
    left: 0;
    opacity: .95;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
    width: inherit;
    width: -webkit-fill-available;
    padding: 20px 5px;
}
.contact-btn:hover:after{
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #fff;
    clear: both;
    content: "";
    height: 0;
    left: 46%;
    opacity: .95;
    position: absolute;
    top: -7px;
    transform: translateY(-16px);
    width: 0;
}
*/
h1 .uppercase{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    /* line-height: 61px; */
    width: 1086px;
}
.header-lower{
    margin-bottom: 25px;
}
.header-lower-text{
    font-weight: 400;
    font-size: 30px;
    
}

footer, .contact-us-outer  {
    background-color: #4a5879;
}

footer .container{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #4F7595;
}

footer .copyright {
    color: #2a3b61;
    font-size: 14px;
    color: white;
    font-weight: 300;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}



/* RESPONSIVE */
@media only screen and (max-width: 1026px) and (min-width: 869px) {
    .header-lower-text{
        font-size: 34px;
    }
    .artcontent{
        padding-left: 50px;
        padding-right: 50px;
    }    
}


@media only screen and (max-width: 868px) {

    header, 
    article,
    footer {
        padding: 0px 22px;
    }
    article{
        padding: 0;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 25px;
        line-height: 36px;
    }
    .h2-header{
        font-size: 25px;
    }
    p{
        font-size: 15px;
        /* color: #fff; */
    }
    
    .header-lower-text{
        font-size: 24px;
    }
    .logo-wrapper{
        height: 100px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }
    .site-logo{
        margin-top: 50px;
    }
    .header-image {
        display: none;
    }
    .site-header-inner {
        padding: 26px 20px 28px;
    }
    .blur-circle-right{
        left: 0;
    }
    .header-description {
        margin-bottom: 26px;
        width: 100%;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }
    .wrapper-inner{
        padding-top: 40px;
    }
    .faq-section-wrapper, .wrapper-inner{
        padding-bottom: 30px;
    }
    .faq-wrapper-outer{
        padding-top: 60px;
    }
    .faq{
        padding-left: 20px;
    }
    .faq-title{
        padding: 10px;
    }
    .faq-btn{
        float: right;
        margin-right: 0;
        font-size: 30px;
        width: 60px;
    }
    .contact-us-outer .contact-us-inner{
        padding-left: 20px;
        padding-right: 20px;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }
    .header-lower{
        margin-top: 25px;

    }
    li{
        position: relative;
    }
    h1 .uppercase{
        font-size: 30px;
        width: 100%;
    }

    .nav-wrapper{
        width: 100%;
    }
    .nav-wrapper-bg{
        display: none;
    }
    .transparent-bg{
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }
    .btn-lis a{
        padding: 20px 30px;
        display: inline-flex;
        background-size: auto;
        font-size: 15px;
    }
    .cta-btns{
        padding:0;
    }
    
    .navigation-articles-desktop{
        display: none;
    }
    .navigation-articles-mobile{
        display: inherit;
    }
    .card-content{
        text-align: center;
        border-radius: 20px;
        padding: 40px 33px;
        box-shadow: 0px 0px 25px 0px #00000014;
        margin-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .card-content h3{
        color: #595959;
    }
    .card-content p{
        color: #6F6C70;
    }
    .features{
        padding-left: 20px;
        padding-right: 20px;
    }
    .features-content{
        text-align: center;
    }
    .features-content-description p{
        color: #6F6C70;
    }
    .create-nft{
        padding-left: 20px;
        padding-right: 20px;
    }
    .steps li{
        width: 100%;
        text-align: left;
        margin-bottom: 12px;
        line-height: 20px;
    }
    .steps li svg{
        display: none;
    }
    .steps li img{
        margin-right: 15px;
    }

    .best-marketplace-wrapper,
    .steps{
        flex-direction: column;
    }
    .best-marketplace{
        padding-left: 20px;
        padding-right: 20px;
    }
    .best-marketplace-wrapper img{
        max-width: 100%;
    }
    .our-team-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }

    .faq-wrapper{
        padding: 0 20px;
    }
    .bg-gradient-inner{
        left: -275px;
    }
    .articles{
        padding: 0 20px;
    }
    .create-nft-content{
        padding-left: 0;
    }

}

@media only screen and (min-width: 768px){
    .navigation-articles-mobile{
        display: none;
    }
}


/* Read more */

.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
}
.m-hidden, .mobile-detector {
    display: none;
}
.m-visible {
    display: initial;
}
.m-hidden {
    display: initial;
}
.m-slide-down-measure p {
    margin: 0!important;
    padding-top: 20px;
}
.m-readmore-btn{
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 0;
    font-weight: 500;
    color: white;
    width: 100%;
    margin: 15px auto 50px;
}

.m-readmore-btn::before {
    content: "";
    cursor: pointer;
    display: inline-block;
    width: 17px;
    height: 17px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0;
}
.m-readmore-btn.read-more-2::before {
    background-image: url("../image/expand.png")
}
.m-readmore-btn:hover.read-more-2::before{
    background-image: url("../image/more-hover.png");
}
.m-readmore-btn.collapse-2::before{
    background-image: url("../image/less.png");
}
.m-readmore-btn:hover.collapse-2::before {
    background-image: url("../image/less-hover.png");
}
.m-readmore-btn * {
    pointer-events: none;
}
.m-visible {
    display: initial;
}
.m-hidden, .mobile-detector {
    display: none;
}